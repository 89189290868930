import React, {useEffect} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Section from "../components/section-layout"
import Logos from "../components/logos"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faLink, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {createBlob, Blob} from "../utils/blob"

function About ({text}) {
  return (
    <section className="py-8 about position-relative text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="lead mb-4">
                {text}
              </div>
              <div>
                <a
                  className="btn btn-primary btn-lg rounded-pill text-white"
                  href="mailto:hello@marmalabs.dev"
                  >
                  Let’s work together!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

function landingPage({data}) {
  const site = data.contentfulPage;
  const members = data.allContentfulMember.nodes;

  useEffect(() => {
    let height = window.innerHeight;
    let width = window.innerWidth;

    const blob = new Blob;
    blob.radius = width < 768 ? width / 2.25 : width / 4.8; 
    createBlob(width, height, blob);
  }, [])

  return (
    <Layout title={site.metaTitle} description={site.metaDescription}>
      <Hero title={site.heroText} />
      <About text={site.about.about} />
      <Section
        title={data.servicesSection.sectionTitle}
        subtitle={data.servicesSection.sectionSubtitle}
        content={
          <div className="row">
            {data.servicesSection.sectionSlots.map( (service, i) => (
              <div className="col-md-6 col-lg-3" key={i}>
                <img alt="alt-text" src={service.slotImage.file.url} height="152" />
                <h4 className="mt-4">{service.slotTitle}</h4>
                <p className="small">{service.slotText.slotText}</p>
              </div>
            ))}
          </div>
        }
      />
      <Section
        title={data.casesSection.sectionTitle}
        subtitle={data.casesSection.sectionSubtitle}
        content={
          <div className="row justify-content-center">
            {data.casesSection.sectionSlots.map( (item, i) => (
              <div
                className={
                  cn("col-md-6 col-lg-5",
                  {
                    "mt-xl-5": i%2
                  })
                }
                key={i}
              >
                <figure className="figure">
                  <img
                    className="figure-img img-fluid mb-3 rounded shadow"
                    src={item.slotImage.file.url}
                    alt="Case study"
                  />
                  <figcaption className="figure-caption text-left">
                    <h4>{item.slotTitle}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.slotText.childMarkdownRemark.html
                      }}
                    />
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        }
      />
      <Section
        title={data.techsSection.sectionTitle}
        subtitle={data.techsSection.sectionSubtitle}
        content={
          <Logos />
        }
      />
      <Section
        title={data.teamSection.sectionTitle}
        subtitle={data.teamSection.sectionSubtitle}
        content={
          <div className="row mt-4 justify-content-center">
            {members.map( (member, i) => (
              <div className="col-lg-4 mb-7 mb-lg-0" key={i}>
                <div className="bg-white p-3 h-100 d-flex flex-column rounded shadow">
                  <img
                    src={member.avatar.file.url}
                    className="rounded-circle mb-3 mx-auto avatar"
                    alt={member.name}
                    width="120"
                    heigh="120"
                  />
                  <h4>{member.name}</h4>
                  <div
                    className="mb-3 small"
                    dangerouslySetInnerHTML={{
                      __html: member.about.childMarkdownRemark.html
                    }}
                  />
                  <ul className="list-inline mt-auto mb-0">
                    {member.email && (
                      <li className="list-inline-item">
                        <a
                        href={"mailto:" + member.email}
                        target="_blank">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                      </li>
                    )}
                    {member.github && (
                      <li className="list-inline-item">
                        <a
                        href={member.github}
                        target="_blank">
                          <FontAwesomeIcon icon={faGithub} />
                        </a>
                      </li>
                    )}
                    {member.linkedIn && (
                      <li className="list-inline-item">
                        <a
                        href={member.linkedIn}
                        target="_blank">
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </li>
                    )}
                    {member.personalLink && (
                      <li className="list-inline-item">
                        <a
                        href={member.personalLink}
                        target="_blank">
                          <FontAwesomeIcon icon={faLink} />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        }
      />
      <Section
        title={data.contactSection.sectionTitle}
        content={
          <div className="pt-4">
            <a
              className="btn btn-primary btn-lg rounded-pill text-white"
              href="mailto:hello@marmalabs.dev"
              >
              Let’s work together!
            </a>
          </div>
        }
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query lendingqueryQuery {
    contentfulPage {
      heroText
      slug
      metaTitle
      metaDescription
      about {
        about
      }
    }
    casesSection: contentfulPageSection(sectionTitle: {eq: "What we offer"}) {
      sectionTitle
      sectionSubtitle
      sectionSlots {
        slotTitle
        slotText {
          childMarkdownRemark {
            html
          }
        }
        slotImage {
          file {
            url
          }
        }
      }
    }
    servicesSection: contentfulPageSection(sectionTitle: {eq: "How we do it"}) {
      sectionTitle
      sectionSubtitle
      sectionSlots {
        slotTitle
        slotText {
          slotText
        }
        slotImage {
          file {
            url
          }
        }
      }
    }
    teamSection: contentfulPageSection(sectionTitle: {eq: "Who we are"}) {
      sectionTitle
      sectionSubtitle
    }
    contactSection: contentfulPageSection(sectionTitle: {eq: "Get in touch"}) {
      sectionTitle
    }
    techsSection: contentfulPageSection(sectionTitle: {eq: "Technologies We Use"}) {
      sectionTitle
      sectionSubtitle
    }
    allContentfulMember {
      nodes {
        name
        github
        linkedIn
        personalLink
        about {
          childMarkdownRemark {
            html
          }
        }
        avatar {
          file {
            url
          }
        }
      }
    }
  }
`

export default landingPage;