import React from 'react'
import "../scss/main.scss"
import { Helmet } from "react-helmet"
import icon from "../img/icon-48x48.png"
import card from "../img/card.png"
import "typeface-montserrat"

const Layout = ({ title, description, children }) => {

  return (
    <div className='wrapper'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:image" content={card} />
        <meta property="twitter:image" content={card} />
        <meta property="twitter:url" content="https://marmalabs.dev" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="theme-color" content="#fef5f8" />
        <link rel="canonical" href="https://marmalabs.dev" />
        <link rel="shortcut icon" href={icon} />
      </Helmet>
      <header className="py-4 position-absolute w-100">
        <div className="container">
          <div className="text-center">
            <span className="supertitle">mrmlbs</span>
          </div>
        </div>
      </header>
      {children}
      <footer className="main-footer py-4 text-center">
        <div className="container">&copy; marmalabs 2019</div>
      </footer>
    </div>
  )
}

export default Layout;
