import React from 'react'
import reactLogo from '../img/logos/react.svg'
import gatsbyLogo from '../img/logos/gatsby.svg'
import netlifyLogo from '../img/logos/netlify.svg'
import awsLogo from '../img/logos/aws.svg'
import shopifyLogo from '../img/logos/shopify.svg'
import typescriptLogo from '../img/logos/typescript.svg'
import nodeLogo from '../img/logos/nodejs.svg'
import firebaseLogo from '../img/logos/firebase.svg'
import bootstrapLogo from '../img/logos/bootstrap.svg'
import nextjsLogo from '../img/logos/nextjs.svg'
import prismicLogo from '../img/logos/prismic.svg'
import contentfulLogo from '../img/logos/contentful.svg'
import zeitLogo from '../img/logos/zeit-now.svg'
import jamstackLogo from '../img/logos/jamstack.svg'
import storyblockLogo from '../img/logos/storyblock.svg'

const logos = [
  {src: contentfulLogo, title: 'Contentful'},
    {src: shopifyLogo, title: 'Shopify'},
    {src: prismicLogo, title: 'Prismic'},
    {src: storyblockLogo, title: 'Storyblock'},
    {src: reactLogo, title: 'React JS'},
    {src: gatsbyLogo, title: 'Gatsby'},
    {src: nextjsLogo, title: 'Next JS'},
    {src: jamstackLogo, title: 'JAMStack'},
    {src: nodeLogo, title: 'Node JS'},
    {src: typescriptLogo, title: 'Typescript'},
    {src: bootstrapLogo, title: 'Bootstrap 4'},
    {src: netlifyLogo, title: 'Netlify'},
    {src: zeitLogo, title: 'ZEIT'},
    {src: awsLogo, title: 'AWS'},
    {src: firebaseLogo, title: 'Firebase'},
]

export default () => (
  <div className='row no-gutters justify-content-center'>
    {logos.map(({src, title}, index) => (
     <div className='col-6 col-md-4 col-lg-2 p-2 p-md-4' key={index}>
       <img className='img-fluid' src={src} title={title} alt={`${title} Logo`} />
     </div>
   ))}
  </div>
)