import React from 'react'

export default ({title, subtitle, content}) => (
  <section className="py-6 text-center">
    <div className="container">
      <h2 className="supertitle">{title}</h2>
      {subtitle && (
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="mb-5">
              {subtitle}
            </div>
          </div>
        </div>
      )}
      {content}
    </div>
  </section>
)